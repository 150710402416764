import * as styles from "./delivery-options.module.scss";
import classNames from "classnames";
import { useEffect, useLayoutEffect, useState } from "react";
import { DeliveryMethod, DeliveryMethodSubTypes, DeliveryOptionType, DeliveryMethod as IDeliveryMethod } from 'ui/src/types';

type CheckoutData = {
    deliveryMethodSubTypes: number[];
    deliveryMethods: DeliveryMethod[];
} & {
    [k: string]: any;
};

const translations = window.app.preloadState.translation;
const checkoutState: CheckoutData = window.app.preloadState.checkout;

export const DeliveryMethods = ({ deliveryMethods, showFreightPopup, manageDeliveryAddressesLink, onDeliveryChange, onValueChange }: { deliveryMethods: IDeliveryMethod[], showFreightPopup: () => void, manageDeliveryAddressesLink: string, onDeliveryChange: () => void, onValueChange: (isDeliveryMethodValid: boolean) => void }) => {
    const [selectedIndex, setSelectedIndex] = useState(deliveryMethods.findIndex(m => m.isSelected));
    const [isTransitMethodValid, setIsTransitMethodValid] = useState(checkoutState.selectedMethodSubType != -1);
    const [transitId, setTransitId] = useState(deliveryMethods[0].options.find(o => o.isSelected)?.id ?? -1);
    const [pickUpId, setpickUpId] = useState(deliveryMethods[1].options.find(o => o.isSelected)?.id ?? -1);
    const [methodId, setMethodId] = useState(deliveryMethods.filter(dm => dm.isSelected)[0]?.methodType ?? 0);

    useEffect(() => {
        onValueChange((methodId == 1 && pickUpId != -1) || (methodId == 0 && isTransitMethodValid && transitId != -1));
    }, [isTransitMethodValid, pickUpId, transitId, methodId ]);

    useEffect(() => {
        setSelectedIndex(deliveryMethods.findIndex(m => m.isSelected));
    }, [deliveryMethods]);

    const onOption = async (method: number, id: string | null) => {
        setMethodId(method);
        method == 0 ? setTransitId(id != null ? +id : -1) : setpickUpId(id != null ? +id : -1)
        if (id == null || id == '-1') return;
        const body = method === 0
            ? { deliveryMethodType: method, deliveryAddressId: id }
            : { deliveryMethodType: method, deliveryBranchId: id };

        const response = await fetch(`/api/cart/update`, {
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            body: JSON.stringify(body),
            method: "POST"
        });
        if (!response.ok) {
            console.error(await response.text());
        }

        if (method === 1) {
            const response = await fetch('/api/inventory/branch/' + id, {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": window.app.preloadState.currentLanguage
                },
                method: 'PUT'
            });
            if (!response.ok) {
                console.error(await response.text());
            }
        }
        onDeliveryChange();
    };

    return (
        <>
            <h3 className={styles.title}>{translations["checkout.deliveryOptions.Title"]}</h3>
            <div>
                <DeliveryMethodTransit
                    method={deliveryMethods[0]}
                    deliveryMethodSubtypes={checkoutState.deliveryMethodSubTypes}
                    selectedMethodSubtype={checkoutState.selectedMethodSubType}
                    isSelected={selectedIndex === 0}
                    isAvailable={deliveryMethods[0].isAvailable}
                    onSelect={() => { setSelectedIndex(0); onOption(deliveryMethods[0].methodType, transitId.toString()); }}
                    manageDeliveryAddressesLink={manageDeliveryAddressesLink}
                    showFreightPopup={showFreightPopup}
                    onSubTypeChange={setIsTransitMethodValid}
                    onOption={(id) => onOption(deliveryMethods[0].methodType, id)} />
                <DeliveryMethodPickUp
                    method={deliveryMethods[1]}
                    isSelected={selectedIndex === 1}
                    isAvailable={deliveryMethods[1].isAvailable}
                    onSelect={() => { setSelectedIndex(1); onOption(deliveryMethods[1].methodType, pickUpId.toString()); }}
                    manageDeliveryAddressesLink={manageDeliveryAddressesLink}
                    showFreightPopup={showFreightPopup}
                    onOption={(id) => onOption(deliveryMethods[1].methodType, id)} />
            </div>
        </>
    );
};

const DeliveryMethodTransit = ({ deliveryMethodSubtypes, selectedMethodSubtype, method, isSelected, isAvailable, onSelect, manageDeliveryAddressesLink, showFreightPopup, onOption, onSubTypeChange }: { deliveryMethodSubtypes: DeliveryMethodSubTypes[], selectedMethodSubtype: DeliveryMethodSubTypes | null, method: IDeliveryMethod, isSelected: boolean, isAvailable: boolean, onSelect: () => void, manageDeliveryAddressesLink: string, showFreightPopup: () => void, onOption: (id: string) => void, onSubTypeChange: (isValid: boolean) => void }) => {

    const [deliveryAddressId, setDeliveryAddressId] = useState(method.options.find(o => o.isSelected)?.id ?? "-1");
    const sideText = translations["checkout.deliveryOptions.Costs." + method.methodName];

    const [selectedSubType, setSelectedSubType] = useState(selectedMethodSubtype);

    const updateSubType = async (value: DeliveryMethodSubTypes | null) => {
        onSubTypeChange(value != null && value >= 0);

        if (value != null && value >= 0) {
            const userApiResponse = await fetch('/api/user/selected-method-subtype', {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": window.app.preloadState.currentLanguage
                },
                method: 'PUT',
                body: JSON.stringify(value)
            });
            if (!userApiResponse.ok) {
                console.error(await userApiResponse.text());
            }

            const body = { deliveryMethodSubType: value };
            const cartApiResponse = await fetch(`/api/cart/update`, {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": window.app.preloadState.currentLanguage
                },
                body: JSON.stringify(body),
                method: "POST"
            });
            if (!cartApiResponse.ok) {
                console.error(await cartApiResponse.text());
            }
        }
    }

    const [isFirstTimeRender, setIsFirstTimeRender] = useState(true);

    useEffect(() => {
        if (isFirstTimeRender && isSelected) {
            onOption(deliveryAddressId);
        }
    }, [isFirstTimeRender]);

    return <div className={classNames("form-radio", styles.radioWrapper, !isAvailable && styles.inactive)}>
        <input id={method.methodName} type="radio" checked={isSelected} onChange={onSelect} />
        <label htmlFor={method.methodName}>
            <p className={styles.radioLabel}>{translations["checkout.deliveryOptions." + method.methodName]}<span onClick={() => method.methodName === "Delivery" && showFreightPopup()}>{sideText}</span></p>
            {deliveryMethodSubtypes.length > 0 && isSelected && <div className={styles.dropdownWrapper}>
                <span className="dropdown__label">{translations["checkout.deliveryMethodsDropdownLabel"]}</span>
                <select className={styles.deliveryOptions} disabled={!isSelected} value={selectedSubType ?? -1} onChange={(ev) => { setSelectedSubType(+ev.target.value); updateSubType(+ev.target.value); setIsFirstTimeRender(false) }}>
                    <option value="-1">{translations["checkout.selectDeliverySubTypeOption"]}</option>
                    {deliveryMethodSubtypes.map(deliveryMethodSubtype => <option value={deliveryMethodSubtype} key={deliveryMethodSubtype}>
                        {translations[`checkout.deliveryMethodSubtypes.${DeliveryMethodSubTypes[deliveryMethodSubtype]}`]}
                    </option>)}
                </select>
                {((selectedSubType ?? -1) == -1 && !isFirstTimeRender) && <span className="form-error">{translations["checkout.deliveryMethodSubtypeNotSelected"]}</span>}
            </div>}
            {method.options.length > 0 && isSelected && <div className={styles.dropdownWrapper}>
                <span className="dropdown__label">{translations["checkout.deliveryOptions.SelectDeliveryAddress"]}</span>
                <select className={styles.deliveryOptions} disabled={!isSelected} value={deliveryAddressId} onChange={ev => { setDeliveryAddressId(ev.target.value); onOption(ev.target.value); setIsFirstTimeRender(false) }}>
                    <option value="-1">{translations["checkout.selectDeliveryAddressOption"]}</option>
                    {method.options.sort((a, b) => a.displayName.localeCompare(b.displayName)).map(deliveryOption => <option value={deliveryOption.id} key={deliveryOption.id}>
                        {deliveryOption.displayName}
                    </option>)}
                </select>
                {(deliveryAddressId == '-1' && !isFirstTimeRender) && <span className="form-error">{translations["checkout.deliveryAddressRequired"]}</span>}
            </div>}
            {isSelected && <a href={manageDeliveryAddressesLink} className={classNames(styles.radioLabel, styles.tip)}>
                {translations["checkout.deliveryOptions.ManageInMySwecon"]}
            </a>}
        </label>
    </div>
}

const DeliveryMethodPickUp = ({ method, isSelected, isAvailable, onSelect, manageDeliveryAddressesLink, showFreightPopup, onOption }: { method: IDeliveryMethod, isSelected: boolean, isAvailable: boolean, onSelect: () => void, manageDeliveryAddressesLink: string, showFreightPopup: () => void, onOption: (id: string) => void }) => {

    const [deliveryAddressId, setDeliveryAddressId] = useState(method.options.find(o => o.isSelected)?.id ?? "-1");
    const sideText = translations["checkout.deliveryOptions.Costs." + method.methodName];

    const [isFirstTimeRender, setIsFirstTimeRender] = useState(true);

    useEffect(() => {
        if (isFirstTimeRender && isSelected) {
            onOption(deliveryAddressId);
        }
    }, [isFirstTimeRender]);

    return <div className={classNames("form-radio", styles.radioWrapper, !isAvailable && styles.inactive)}>
        <input id={method.methodName} type="radio" checked={isSelected} onChange={onSelect} />
        <label htmlFor={method.methodName}>
            <p className={styles.radioLabel}>{translations["checkout.deliveryOptions." + method.methodName]}<span onClick={() => method.methodName==="Delivery" && showFreightPopup()}>{sideText}</span></p>
            {isSelected && <div className={styles.dropdownWrapper}>
                <select className={styles.deliveryOptions} disabled={!isSelected || method.options.length == 0} value={deliveryAddressId} onChange={ev => { setDeliveryAddressId(ev.target.value); onOption(ev.target.value); setIsFirstTimeRender(false); }}>
                    <option value="-1">{translations["checkout.selectBranchOption"]}</option>
                    {method.options.sort((a, b) => a.displayName.localeCompare(b.displayName)).map(deliveryOption => <option value={deliveryOption.id} key={deliveryOption.id}>
                        {deliveryOption.displayName}
                    </option>)}
                </select>
                {(deliveryAddressId == '-1' && !isFirstTimeRender) && <span className="form-error">{translations["checkout.deliveryBranchNotSelected"]}</span>}
            </div>}
        </label>
    </div>
}

export const DeliveryOptions = ({ initialDeliveryOption, fullDeliveryText, partialDeliveryText, onDeliveryChange }: { initialDeliveryOption: DeliveryOptionType | null, fullDeliveryText: string, partialDeliveryText: string, onDeliveryChange: () => void }) => {

    const [deliveryOption, setDeliveryOption] = useState(initialDeliveryOption ?? DeliveryOptionType.Partial);

    const updateOption = (value: DeliveryOptionType) => async () => {
        setDeliveryOption(value);
        const response = await fetch(`/api/cart/update`, {
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            body: JSON.stringify({
                deliveryOptionType: value
            }),
            method: "POST"
        });
        if (!response.ok) {
            console.error(await response.text());
        }
        onDeliveryChange();
    }

    return (
        <div className={styles.deliveryOptionsContainer}>
            <h3 className={styles.title}>{translations["checkout.deliveryOptions.DeliveryOptionsHeading"]}</h3>
            <div className={styles.deliveryContainer}>
                <input id={"fullDelivery"} type="radio" checked={deliveryOption === DeliveryOptionType.Full} onChange={updateOption(DeliveryOptionType.Full)} />
                <label htmlFor={"fullDelivery"}>{translations["checkout.deliveryOptions.FullDelivery"]}</label>
                <div className={styles.deliveryText} style={{ height: deliveryOption === DeliveryOptionType.Full ? 'unset' : '0' }}
                    dangerouslySetInnerHTML={{ __html: fullDeliveryText }}>
                </div>
            </div>
            <div className={styles.deliveryContainer}>
                <input id={"partialDelivery"} type="radio" checked={deliveryOption === DeliveryOptionType.Partial} onChange={updateOption(DeliveryOptionType.Partial)} />
                <label htmlFor={"partialDelivery"}>{translations["checkout.deliveryOptions.PartialDelivery"]}</label>
                <div className={styles.deliveryText} style={{ height: deliveryOption === DeliveryOptionType.Partial ? 'unset' : '0' }}
                    dangerouslySetInnerHTML={{ __html: partialDeliveryText }}>
                </div>
            </div>
        </div>
    );
}